import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DashboardTemplate from '@templates/DashboardTemplate';
import DashboardInvoicesView from '@views/DashboardInvoicesView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const DashboardInvoicesPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DashboardTemplate>
      <PageMeta title={t('invoices')} />
      <DashboardInvoicesView />
    </DashboardTemplate>
  );
};

export default DashboardInvoicesPage;
