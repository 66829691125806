import useFetchApi from '@helpers/useFetchApi';
import { useQuery } from 'react-query';

type FetchInvoicesResponse = Invoice[];

interface Invoice {
  amountPaid: number;
  createdAt: number;
  id: string;
  paid: boolean;
  url: string;
}

export default function useFetchInvoices(): SuspendedQueryResult<FetchInvoicesResponse> {
  const fetchApi = useFetchApi();

  return useQuery('invoices', () => fetchApi.get('/api/invoices/'), {
    cacheTime: 1000 * 60 * 60, // 1 hour
  }) as SuspendedQueryResult<FetchInvoicesResponse>;
}
