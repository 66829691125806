import useFetchInvoices from '@api/useFetchInvoices';
import useTranslation from '@helpers/useTranslation';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Box, Heading, Table } from 'react-bulma-components';

const DashboardInvoicesView: FC = () => {
  const { t } = useTranslation(['invoices', 'pages']);

  const { data: invoices } = useFetchInvoices();

  return (
    <Box>
      <Heading>{t('pages:invoices')}</Heading>
      {invoices.length > 0 ? (
        <Table hoverable size="fullwidth">
          <thead>
            <tr>
              <td>{t('date')}</td>
              <td>{t('payment')}</td>
              <td>{t('status')}</td>
              <td>{t('invoice')}</td>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => (
              <tr>
                <td>{format(invoice.createdAt, 'MMM d, yyyy')}</td>
                <td>${(invoice.amountPaid / 100).toFixed(2)}</td>
                <td>{invoice.paid ? t('paid') : t('unpaid')}</td>
                <td>
                  <a href={invoice.url} target="_blank" rel="noreferrer">
                    {t('download')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>{t('noInvoicesFound')}</p>
      )}
    </Box>
  );
};

export default DashboardInvoicesView;
